








































































import {DaveForm} from "@/classes/Forms/DaveForm";
import {FinancialStatementCollection} from "@/classes/FinancialStatementCollection";
import {FinancialStatement} from "@/classes/FinancialStatement";
import {Component, Vue} from 'vue-property-decorator';
import {ActiveForms} from "@/classes/Forms";


@Component({
    components: {},
})
export default class App extends Vue {
    tab = 0;// || 2;
    panel_index = 0;
    forms: DaveForm[];
    ticker: string;
    tickerRules = [
        v => !!v || 'Name is required'
    ];
    financialStatements: FinancialStatementCollection;

    validators = {
        required: value => !!value || 'This field is required'
    }

    constructor() {
        super();
        this.ticker = 'nflx';
        this.financialStatements = new FinancialStatementCollection;

        this.forms = ActiveForms.map(className => {
            return new className(this.financialStatements);
        });
    }

    fetchInfo() {
        fetch(`/.netlify/functions/ticker-lookup?ticker=${this.ticker}`)
            .then(result => result.json())
            .then(({data}) => {
                this.financialStatements.items = (data.financial_statements.map(x => {
                    return new FinancialStatement(x.name, x.items)
                }));
            });
    }

    get tabHeadings() {
        return ['Dave Forms', ...this.financialStatements.items.map(s => s.name)];
    }

    mounted() {
        // console.clear();
        // this.fetchInfo();
    }
}

